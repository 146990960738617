import Home from './components/Home'
import CreateGame from './components/CreateGame'
import GameInfo from './components/GameInfo'
import Player from './components/Player'
import PlayerInfo from './components/PlayerInfo'
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom'

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/game' element={<Home/>}/>
        <Route path='/game/create' element={<CreateGame/>}/>
        <Route path='/game/:gameCode' element={<GameInfo/>}/>
        <Route path='/game/:gameCode/player' element={<Player/>}/>
        <Route path='/game/:gameCode/player/:playerCode' element={<PlayerInfo/>}/>
      </Routes>
    </Router>
  )
}
