'use client'
import * as React from 'react'
import { AddIcon, ArrowBackIcon, DeleteIcon } from "@chakra-ui/icons";
import { 
  Box, 
  Button, 
  HStack, 
  Heading, 
  IconButton, 
  Input, 
  Spacer, 
  Text, 
  VStack,
  Link,
  Stack,
  Checkbox
} from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from 'react-router-dom'

const initialPlayers = [
  { name: "", email: "" },
  { name: "", email: "" },
  { name: "", email: "" },
  { name: "", email: "" },
  { name: "", email: "" },
  { name: "", email: "" },
];

const CreateGame = () => {
  const [gameName, setGameName] = useState("");
  const [mapURL, setMapURL] = useState("");
  const [players, setPlayers] = useState(initialPlayers);
  const [usingProphecyOfKings, setUsingProphecyOfKings] = useState(false);
  const [gameNameInvalid, setGameNameInvalid] = useState(false);
  const [mapUrlInvalid, setMapUrlInvalid] = useState(false);
  const [playerNamesInvalid, setPlayerNamesInvalid] = useState([]);
  const [playerEmailsInvalid, setPlayerEmailsInvalid] = useState([]);
  const [requiredFieldError, setRequiredFieldError] = useState(false)

  const navigate = useNavigate()
  
  const handleAddPlayer = () => {
    if (players.length < 8) {
      setPlayers([...players, { name: "", email: "" }]);
    }
  };

  const handleDeletePlayer = (index) => {
    if (players.length > 2) {
      const newPlayers = [...players];
      newPlayers.splice(index, 1);
      setPlayers(newPlayers);
    }
  };

  const handleCreateGame = async () => {
    const isValid = validateForm()
    if (!isValid) { return }
    const payload = {
      game_name: gameName,
      map_url: mapURL,
      players: players,
      prophecyOfKings: usingProphecyOfKings
    }
    const response = await fetch('https://api.ti4setup.markgatesman.com/game', {
      method: 'POST',
      body: JSON.stringify(payload)
    }).then(res => res.json()).catch(error => console.error(error))
    if (response){
      navigate( `/game/${response.game_code}`)
    }
  }

  const validateForm = () => {
    setGameNameInvalid(gameName === "")
    setMapUrlInvalid(mapURL === "")

    const isInvalidName = []
    const isInvalidEmail = []
    players.forEach((player, index) => {
      isInvalidName[index] = player.name === ""
      isInvalidEmail[index] = player.email === ""
    })
    setPlayerNamesInvalid(isInvalidName)
    setPlayerEmailsInvalid(isInvalidEmail)

    const anyFormErrors = [gameName === "", mapURL === "", ...isInvalidName, ...isInvalidEmail].includes(true)
    setRequiredFieldError(anyFormErrors)
    return !anyFormErrors
  }

  return (
    <VStack direction="column" align="center" justify="top" height="100vh">
      <Box height={['10vh', '10vh','15vh']} padding='2rem' textAlign='center'>
        <Heading>Create New Game</Heading>
      </Box>
      <VStack>
        <Input
          isInvalid={gameNameInvalid}
          placeholder="Game Name/Seed"
          value={gameName}
          onChange={(e) => setGameName(e.target.value)}
          width={['xs', 'sm', 'md']}
          variant='filled'
        />
        <Input
          isInvalid={mapUrlInvalid}
          placeholder="Map URL"
          value={mapURL}
          onChange={(e) => setMapURL(e.target.value)}
          width={['xs', 'sm', 'md']}
          variant='filled'
        />
        <Spacer />
        <Text fontSize='xl' as='b' >
          Players
        </Text>
        {players.map((player, index) => (
          <HStack key={index} align="center" width={['xs', 'md', 'lg']}>
            <Input
              isInvalid={playerNamesInvalid[index]}
              placeholder="Name"
              value={player.name}
              onChange={(e) => {
                const newPlayers = [...players];
                newPlayers[index].name = e.target.value;
                setPlayers(newPlayers);
              }}
              variant='filled'
            />
            <Input
              isInvalid={playerEmailsInvalid[index]}
              placeholder="Email"
              value={player.email}
              onChange={(e) => {
                const newPlayers = [...players];
                newPlayers[index].email = e.target.value;
                setPlayers(newPlayers);
              }}
              variant='filled'
            />
            <IconButton
              aria-label="Delete player"
              icon={<DeleteIcon />}
              onClick={() => handleDeletePlayer(index)}
            />
          </HStack>
        ))}
        <Box width={['xs', 'md', 'lg']} textAlign='right'>
          <Button onClick={handleAddPlayer} leftIcon={<AddIcon />} colorScheme="linkedin">
            Add Player
          </Button>
        </Box>
        <Spacer />
        <Stack spacing={[1, 5]} direction={['column', 'row']} margin={[1, 5]}>
          <Checkbox value='prophecyOfKings' onChange={(e) => setUsingProphecyOfKings(e.target.checked)}>Prophecy of Kings Expansion</Checkbox>
        </Stack>
        <Spacer />
        <Button colorScheme="whatsapp" onClick={handleCreateGame}>Create Game</Button>
        <Spacer />
        {requiredFieldError && <Text fontSize='l' as='b' color='red' >
          Please fill out the required fields
        </Text>}
      </VStack>
      <Link href='/' color={'teal'} margin={'1rem'} fontSize={'xl'}><ArrowBackIcon/> Go Back</Link>
    </VStack>
  );
};

export default CreateGame;
