'use client'
import { Box, Button, Flex, Heading, Input, Link, InputGroup, InputRightElement, Spacer, VStack } from "@chakra-ui/react"
import { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom'

const Player = () => {
  const [playerCode, setPlayerCode] = useState('');

  const params = useParams()
  const navigate = useNavigate()
  const gameUrl = `/game/${params.gameCode}`

  useEffect(() => {
    const storedPlayerCode = localStorage.getItem('playerCode')
    if(storedPlayerCode) {
      navigate(`${gameUrl}/player/${storedPlayerCode}`)
    }
  }, [])

  const handlePlayerCodeSubmit = () => {
    localStorage.setItem('playerCode', playerCode)
    navigate(`${gameUrl}/player/${playerCode}`)
  };

  return (
    <Flex direction="column" align="center" justify="top" height="100vh">
      <Box h={['15vh', '15vh', '20vh']} p={'2.5rem'}>
        <Heading>Player Info</Heading>
      </Box>
      <VStack>
        <InputGroup size='md' width={['2xs', 'sm', 'md']} variant='filled'>
          <Input 
            placeholder='Enter Player Code' 
            onChange={(e) => setPlayerCode(e.target.value)}
            onKeyDown={(e) => {
              if(e.key === 'Enter') {handlePlayerCodeSubmit()}
            }}
          />
          <InputRightElement width='4.5rem'>
            <Button colorScheme='blue' onClick={handlePlayerCodeSubmit}>Submit</Button>
          </InputRightElement>
        </InputGroup>
        <Spacer />
        <Link href={`/game/${params.gameCode}`} color={'teal'}>Go To Game Page</Link>
      </VStack>
    </Flex>
  )
}

export default Player;
