import { Box, Button, Flex, Heading, Input, InputGroup, InputRightElement } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const Home = () => {
  const [gameCode, setGameCode] = useState('')

  const navigate = useNavigate()

  useEffect(() => {
    const storedGameCode = localStorage.getItem('gameCode')
    if(storedGameCode) {
      navigate(`/game/${storedGameCode}`)
    }
  }, [])

  const handleGameCodeSubmit = () => {
    localStorage.setItem('gameCode', gameCode)
    navigate(`/game/${gameCode}`)
  }

  const handleCreateNewGame = () => { navigate('/game/create') }

  return (
    <Flex direction='column' align='center' justify='top' height='100vh'>
      <Box height={['15vh', '15vh', '20vh']} padding='2rem' textAlign='center'>
        <Heading size={['lg', 'xl']}>Twilight Imperium 4</Heading>
        <Heading size={['lg', 'xl']}>Galactic Setup System</Heading>
      </Box>
      <InputGroup size='md' width={['2xs', 'sm', 'md']} variant='filled' onSubmit={handleGameCodeSubmit}>
        <Input 
          placeholder='Enter Game Code' 
          onChange={(e) => setGameCode(e.target.value)}
          onKeyDown={(e) => {
            if(e.key === 'Enter') {handleGameCodeSubmit()}
          }}
        />
        <InputRightElement width='4.5rem'>
          <Button colorScheme='blue' onClick={handleGameCodeSubmit} width={['xs','sm', 'md']}>Submit</Button>
        </InputRightElement>
      </InputGroup>
      <Button colorScheme='teal' mt={2} onClick={handleCreateNewGame}>Create New Group</Button>
    </Flex>
  )
}

export default Home