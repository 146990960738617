import { 
  Box, VStack, HStack, Stack, StackDivider,
  Text, Heading, Link,
  Card, CardHeader, CardBody, 
  Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom'

const GameInfo = () => {
  const params = useParams()
  const navigate = useNavigate()

  const [gameCode, setGameCode] = useState(params.gameCode)
  const [gameName, setGameName] = useState('')
  const [mapUrl, setMapUrl] = useState('')
  const [objectives, setObjectives] = useState([])
  const [players, setPlayers] = useState([])
  const [error, setError] = useState('')

  const fetchGameData = async () => {
    await fetch(`https://api.ti4setup.markgatesman.com/game?game_code=${gameCode}`)
    .then(res => res.json())
    .then(data => {
      setGameCode(data?.game_code)
      setGameName(data?.game_name)
      setMapUrl(data?.map_url)
      setPlayers(data?.gamestate.players || [])
      setObjectives(data?.gamestate.objectives || [])
    })
    .catch(error => {
      setError(`Invalid Game Code: ${gameCode}`)
      localStorage.clear()
      console.error(error)
    })
  }

  useEffect(() => {
    localStorage.setItem('gameCode', params.gameCode)
    setGameCode(params.gameCode)
    fetchGameData()
  }, [])

  const goToHomePageHandler = () => {
    localStorage.clear()
    navigate(`/`)
  }

  if(error) {
    return(
      <VStack align="center" justify="center" height="100vh">
        <Text fontSize="3xl">{error}</Text>
        <Link href='/' color={'teal'} margin={'1rem'} fontSize={'xl'}><ArrowBackIcon/> Go Back</Link>
      </VStack>
    )
  }

  return (
    <VStack align="center" justify="top" height="100vh" padding='1rem'>
      <Box padding='1rem'>
        <Heading size='3xl'>{gameName}</Heading>
      </Box>
      <Link onClick={goToHomePageHandler} color={'teal'}>Go To Game Login</Link>
      <Link href={`${mapUrl}`} fontSize='2xl' fontWeight='semibold' color='darkblue' isExternal>Game Map</Link>
      {objectives.length > 0 && <Heading fontSize="3xl" color='teal'>Public Objectives</Heading>}
      <VStack align='left' divider={<StackDivider borderColor='lightgray'/>}>
        {objectives.map((objective, index) => (
          <VStack key={`objective${index}`} align='left'>
            <Text size='md' fontWeight={'bold'}>
              {objective.name}
            </Text>
            <Text>{objective.description}</Text>
          </VStack>
        ))}
      </VStack>
      <VStack my={4} spacing={'4'} padding='1rem'>
        <Box paddingBottom='1rem'>
          <Heading fontSize="4xl" color='teal'>Players</Heading>
        </Box>
        <VStack align='center' >
          {players.map((player, index) => (
            <PlayerCard
              key={index}
              gameCode={gameCode}
              name={player.name}
              email={player.email}
              waiting_to_text={player.waiting_to_text}
              faction={player.faction}
              slice={player.slice}
              startingTech={player.tech}
              strategy={player.strategy}
            />
          ))}
        </VStack>
      </VStack>
    </VStack>
  );
};

const PlayerCard = (props) => {
  return(
    <Card width={'90vw'}>
    <CardHeader>
      <HStack>
        <Heading size='md'><Link href={`/game/${props.gameCode}/player`}>{props.name}</Link></Heading>
        {props.waiting_to_text && <Text color={'green'}>{props.waiting_to_text}..</Text>}
      </HStack>
    </CardHeader>

    <Accordion allowMultiple id='selectionsDropdown' defaultIndex={[0]}>
      <AccordionItem>
        <AccordionButton>
          <Heading as="span" flex='1' textAlign='left' size='sm' textTransform={'uppercase'} color={'gray'}>
            Selections
          </Heading>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pb={4}>
          <CardBody>
            <Stack divider={<StackDivider />} spacing='4'>
              {props.faction && <Box>
                <Heading size='xs' textTransform='uppercase'>
                  Faction
                </Heading>
                <Text pt='2' fontSize='sm'>
                  {props.faction}
                </Text>
              </Box>}
              {props.slice && <Box>
                <Heading size='xs' textTransform='uppercase'>
                  Slice
                </Heading>
                <Text pt='2' fontSize='sm'>
                  {props.slice}
                </Text>
              </Box>}
              {props.startingTech && <Box>
                <Heading size='xs' textTransform='uppercase'>
                  Starting Tech
                </Heading>
                <Text pt='2' fontSize='sm'>
                  {props.startingTech}
                </Text>
              </Box>}
              {props.strategy && <Box>
                <Heading size='xs' textTransform='uppercase'>
                  Strategy
                </Heading>
                <Text pt='2' fontSize='sm'>
                  {props.strategy}
                </Text>
              </Box>}
            </Stack>
          </CardBody>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  </Card>
  )
}

export default GameInfo;
